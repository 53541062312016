// TODO - to be removed, just a POC for iframe communication

import * as React from "react"
import { useEffect, useState } from "react"
import { DefaultButton, TextField } from "office-ui-fabric-react"
import styled from "styled-components"

const IframeTarget = () => {
  const [message, setMessage] = useState("NO MESSAGE YET")
  const [parentUrl, setParentUrl] = useState("Initial")
  const [messageToParent, setMessageToParent] = useState("...")

  function handleShinyMessageEvent(e: CustomEvent) {
    // eslint-disable-next-line no-console
    console.log("within iframe target", e)
    setMessage(e.detail || "Received event with no message :/")
  }

  function handleLocationChangeEvent(e: CustomEvent) {
    // eslint-disable-next-line no-console
    console.log("within iframe target", e)
    setParentUrl(e.detail || "Received event with no message :/")
  }

  useEffect(() => {
    window.document.addEventListener("shiny-message", handleShinyMessageEvent)
    window.document.addEventListener(
      "location-change",
      handleLocationChangeEvent
    )

    return () => {
      window.document.removeEventListener(
        "shiny-message",
        handleShinyMessageEvent
      )
      window.document.removeEventListener(
        "location-change",
        handleLocationChangeEvent
      )
    }
  }, [])

  const postToTop = () => {
    window.parent.postMessage({ message: messageToParent }, "*")
  }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    postToTop()
  }

  return (
    <IframeBoundary>
      <h1>Thats a page under iframe-target</h1>
      Message: {message}
      <br />
      Parent URL: {parentUrl}
      <form onSubmit={onSubmit}>
        <TextField
          value={messageToParent}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMessageToParent(e.target.value)
          }
        />
        <DefaultButton text={"Post to parent"} type="submit" />
      </form>
    </IframeBoundary>
  )
}

const IframeBoundary = styled.div`
  width: 100%;
  height: 100vh;
  border: 2px solid black;
  padding: 15px;
`

export default IframeTarget
